import API from './../Api'

class CatalogoService {
  static getPaises() {
    return API({
      method: 'GET',
      url: '/api/v1/catalogo/pais/'
    })
  }

  static getTipoBanca() {
    return API({
      method: 'GET',
      url: '/api/v1/catalogo/tipobanca/'
    })
  }
  static getTipoProyecto(token) {
    return API({
      method: 'GET',
      url: '/api/v1/catalogo/tipoproyecto/',
      headers: {
        Authorization: 'Bearer ' + token,
        'Strict-Transport-Security':
          'max-age=31536000; includeSubDomains; preload',
        'Content-Security-Policy':
          "default-src https: data: blob: 'unsafe-inline' 'unsafe-eval'",
        'X-Content-Type-Options': 'nosniff',
        'X-Frame-Options': 'DENY',
        'X-XSS-Protection': '1; mode=block',
        'X-Content-Type-Options': 'nosniff',
        'Referrer-Policy': 'no-referrer-when-downgrade',
        'Permissions-Policy': 'camera=(), microphone=(), geolocation=()'
      }
    })
  }
  static getFaseProyecto(token) {
    return API({
      method: 'GET',
      url: '/api/v1/catalogo/fase/',
      headers: {
        Authorization: 'Bearer ' + token,
        'Strict-Transport-Security':
          'max-age=31536000; includeSubDomains; preload',
        'Content-Security-Policy':
          "default-src https: data: blob: 'unsafe-inline' 'unsafe-eval'",
        'X-Content-Type-Options': 'nosniff',
        'X-Frame-Options': 'DENY',
        'X-XSS-Protection': '1; mode=block',
        'X-Content-Type-Options': 'nosniff',
        'Referrer-Policy': 'no-referrer-when-downgrade',
        'Permissions-Policy': 'camera=(), microphone=(), geolocation=()'
      }
    })
  }
  static getCategoria(token) {
    return API({
      method: 'GET',
      url: 'api/v1/catalogo/categoria/',
      headers: {
        Authorization: 'Bearer ' + token,
        'Strict-Transport-Security':
          'max-age=31536000; includeSubDomains; preload',
        'Content-Security-Policy':
          "default-src https: data: blob: 'unsafe-inline' 'unsafe-eval'",
        'X-Content-Type-Options': 'nosniff',
        'X-Frame-Options': 'DENY',
        'X-XSS-Protection': '1; mode=block',
        'X-Content-Type-Options': 'nosniff',
        'Referrer-Policy': 'no-referrer-when-downgrade',
        'Permissions-Policy': 'camera=(), microphone=(), geolocation=()'
      }
    })
  }
  static getFamilia(token) {
    return API({
      method: 'GET',
      url: 'api/v1/catalogo/familia/',
      headers: {
        Authorization: 'Bearer ' + token,
        'Strict-Transport-Security':
          'max-age=31536000; includeSubDomains; preload',
        'Content-Security-Policy':
          "default-src https: data: blob: 'unsafe-inline' 'unsafe-eval'",
        'X-Content-Type-Options': 'nosniff',
        'X-Frame-Options': 'DENY',
        'X-XSS-Protection': '1; mode=block',
        'X-Content-Type-Options': 'nosniff',
        'Referrer-Policy': 'no-referrer-when-downgrade',
        'Permissions-Policy': 'camera=(), microphone=(), geolocation=()'
      }
    })
  }
  static getMoneda(token) {
    return API({
      method: 'GET',
      url: 'api/v1/catalogo/moneda/',
      headers: {
        Authorization: 'Bearer ' + token,
        'Strict-Transport-Security':
          'max-age=31536000; includeSubDomains; preload',
        'Content-Security-Policy':
          "default-src https: data: blob: 'unsafe-inline' 'unsafe-eval'",
        'X-Content-Type-Options': 'nosniff',
        'X-Frame-Options': 'DENY',
        'X-XSS-Protection': '1; mode=block',
        'X-Content-Type-Options': 'nosniff',
        'Referrer-Policy': 'no-referrer-when-downgrade',
        'Permissions-Policy': 'camera=(), microphone=(), geolocation=()'
      }
    })
  }
}

export default CatalogoService
