import API from '../Api'

class UserService {
  static create(data, token) {
    return API({
      method: 'POST',
      url: '/api/v1/usuario/user/',
      data,
      headers: {
        Authorization: 'Bearer ' + token,
        'Strict-Transport-Security':
          'max-age=31536000; includeSubDomains; preload',
        'Content-Security-Policy':
          "default-src https: data: blob: 'unsafe-inline' 'unsafe-eval'",
        'X-Content-Type-Options': 'nosniff',
        'X-Frame-Options': 'DENY',
        'X-XSS-Protection': '1; mode=block',
        'X-Content-Type-Options': 'nosniff',
        'Referrer-Policy': 'no-referrer-when-downgrade',
        'Permissions-Policy': 'camera=(), microphone=(), geolocation=()'
      }
    })
  }
  static edit(id, data, token) {
    return API({
      method: 'put',
      url: '/api/v1/usuario/user/' + id + '/',
      data,
      headers: {
        Authorization: 'Bearer ' + token,
        'Strict-Transport-Security':
          'max-age=31536000; includeSubDomains; preload',
        'Content-Security-Policy':
          "default-src https: data: blob: 'unsafe-inline' 'unsafe-eval'",
        'X-Content-Type-Options': 'nosniff',
        'X-Frame-Options': 'DENY',
        'X-XSS-Protection': '1; mode=block',
        'X-Content-Type-Options': 'nosniff',
        'Referrer-Policy': 'no-referrer-when-downgrade',
        'Permissions-Policy': 'camera=(), microphone=(), geolocation=()'
      }
    })
  }
  static getAll(token) {
    return API({
      method: 'GET',
      url: '/api/v1/usuario/user/',
      headers: {
        Authorization: 'Bearer ' + token,
        'Strict-Transport-Security':
          'max-age=31536000; includeSubDomains; preload',
        'Content-Security-Policy':
          "default-src https: data: blob: 'unsafe-inline' 'unsafe-eval'",
        'X-Content-Type-Options': 'nosniff',
        'X-Frame-Options': 'DENY',
        'X-XSS-Protection': '1; mode=block',
        'X-Content-Type-Options': 'nosniff',
        'Referrer-Policy': 'no-referrer-when-downgrade',
        'Permissions-Policy': 'camera=(), microphone=(), geolocation=()'
      }
    })
  }
  static getById(id, token) {
    return API({
      method: 'GET',
      url: `api/v1/usuario/user/${id}`,
      headers: {
        Authorization: 'Bearer ' + token,
        'Strict-Transport-Security':
          'max-age=31536000; includeSubDomains; preload',
        'Content-Security-Policy':
          "default-src https: data: blob: 'unsafe-inline' 'unsafe-eval'",
        'X-Content-Type-Options': 'nosniff',
        'X-Frame-Options': 'DENY',
        'X-XSS-Protection': '1; mode=block',
        'X-Content-Type-Options': 'nosniff',
        'Referrer-Policy': 'no-referrer-when-downgrade',
        'Permissions-Policy': 'camera=(), microphone=(), geolocation=()'
      }
    })
  }
  static delete(id, token) {
    return API({
      method: 'DELETE',
      url: `/api/v1/usuario/user/${id}`,
      headers: {
        Authorization: 'Bearer ' + token,
        'Strict-Transport-Security':
          'max-age=31536000; includeSubDomains; preload',
        'Content-Security-Policy':
          "default-src https: data: blob: 'unsafe-inline' 'unsafe-eval'",
        'X-Content-Type-Options': 'nosniff',
        'X-Frame-Options': 'DENY',
        'X-XSS-Protection': '1; mode=block',
        'X-Content-Type-Options': 'nosniff',
        'Referrer-Policy': 'no-referrer-when-downgrade',
        'Permissions-Policy': 'camera=(), microphone=(), geolocation=()'
      }
    })
  }
  static tipoUsuario(token) {
    return API({
      method: 'GET',
      url: '/api/v1/usuario/tipo_usuario/',
      headers: {
        Authorization: 'Bearer ' + token,
        'Strict-Transport-Security':
          'max-age=31536000; includeSubDomains; preload',
        'Content-Security-Policy':
          "default-src https: data: blob: 'unsafe-inline' 'unsafe-eval'",
        'X-Content-Type-Options': 'nosniff',
        'X-Frame-Options': 'DENY',
        'X-XSS-Protection': '1; mode=block',
        'X-Content-Type-Options': 'nosniff',
        'Referrer-Policy': 'no-referrer-when-downgrade',
        'Permissions-Policy': 'camera=(), microphone=(), geolocation=()'
      }
    })
  }
  static search(search, token) {
    return API({
      method: 'GET',
      url: '/api/v1/usuario/user/?search=' + search,
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
  }

  static downloadLogById(id, token) {
    return API({
      method: 'GET',
      url: `/api/v1/usuario/reporte/?user=${id}`,
      headers: {
        Authorization: 'Bearer ' + token,
        'Strict-Transport-Security':
          'max-age=31536000; includeSubDomains; preload',
        'Content-Security-Policy':
          "default-src https: data: blob: 'unsafe-inline' 'unsafe-eval'",
        'X-Content-Type-Options': 'nosniff',
        'X-Frame-Options': 'DENY',
        'X-XSS-Protection': '1; mode=block',
        'X-Content-Type-Options': 'nosniff',
        'Referrer-Policy': 'no-referrer-when-downgrade',
        'Permissions-Policy': 'camera=(), microphone=(), geolocation=()'
      }
    })
  }

  static downloadLog(token) {
    return API({
      method: 'GET',
      url: `/api/v1/usuario/reporte/`,
      headers: {
        Authorization: 'Bearer ' + token,
        'Strict-Transport-Security':
          'max-age=31536000; includeSubDomains; preload',
        'Content-Security-Policy':
          "default-src https: data: blob: 'unsafe-inline' 'unsafe-eval'",
        'X-Content-Type-Options': 'nosniff',
        'X-Frame-Options': 'DENY',
        'X-XSS-Protection': '1; mode=block',
        'X-Content-Type-Options': 'nosniff',
        'Referrer-Policy': 'no-referrer-when-downgrade',
        'Permissions-Policy': 'camera=(), microphone=(), geolocation=()'
      }
    })
  }
}

export default UserService
