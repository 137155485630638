import DashboardPage from 'views/Dashboard/Proyecto'
import DashboardRefencia from 'views/Dashboard/Referencia'
import DashboardConsolidado from 'views/Dashboard/Consolidado'

import GestiónFuentes from 'views/Admin/Organizacion/Fuentes'
import Organizacion from 'views/Admin/Organizacion/Form'
import UserList from 'views/UserProfile/List'
import Profile from 'views/UserProfile/Profile'
import UserForm from 'views/UserProfile/Form'
import ProyectoLista from 'views/Proyecto/Lista'
import ProyectoForm from 'views/Proyecto/Form'
import PreviewForm from 'views/Proyecto/Preview'

import FactorTree from 'views/Factor/tree'
import Registro from 'views/Registro'
import HuellaFicha from 'views/HuellaConsolidada'
import HuellaReferencia from 'views/HuellaReferencia'
import Login from 'views/LoginAdmin'
import LoginAdmin from 'views/Login'
import Logaut from 'views/Logaut'

import RecuperaContrasena from 'views/RecuperaContrasena'
import RegistroHuella from 'views/RegistroHuella/Lista'
import RegistroItem from 'views/RegistroHuella/Registro'
import ReferenciaLista from 'views/ReferenciaHuella/Lista'
import ReferencaiaItem from 'views/ReferenciaHuella/Registro'
import RegistroData from 'views/RegistroHuella/RegistroData'
import ReferenciaData from 'views/ReferenciaHuella/RegistroData'

import RegistroHistorico from 'views/RegistroHuella/Historico'
import Invitacion from 'views/Invitacion'
import Gracias from 'views/Gracias'


const dashboardRoutes = [
  {
    path: '/dashboard',
    menu: true,
    rol: [1, 2, 3, 4],
    base: 'dashboard-referencia',
    name: 'Situación de Referencia',
    nameBase: 'Situación de Referencia',
    toltip:
      'En esta pantalla se puede consultar información relacionada a las emisiones de gases de efecto invernadero de un proyecto y su situación de referencia.',
    help: 'En esta pantalla los usuarios pueden consultar las emisiones de GEI de un proyecto, por tipo de proyecto, evaluando la situación de referencia en ausencia del proyecto, contra las emisiones potenciales del proyecto. Los reportes generados incluyen las emisiones por Alcance I, II y III, y se proyectan en función de los años de duración de las fases de construcción y operación, y se evalúa las adicionalidades de GEI, ya sean incrementos o mitigaciones en los niveles de emisión de GEI.',
    component: DashboardRefencia,
    type: 'dash',
    layout: '/admin'
  },
  {
    path: '/dashboard-proyecto',
    menu: true,
    rol: [1, 2, 3, 4],
    base: 'dashboard',
    name: 'Consulta de proyectos',
    nameBase: 'Consulta de proyectos',
    toltip:
      'En esta pantalla se puede consultar información relacionada a las emisiones de gases de efecto invernadero de un proyecto.',
    help: 'En esta pantalla los usuarios pueden consultar las emisiones de GEI de un proyecto, por tipo de proyecto, por año y por etapa (planeación o implementación). Los reportes generados incluyen las emisiones por Alcance I, II y III, las emisiones por las fases de construcción y operativas del proyecto, y las emisiones por etapa, ya sea de planeación (pre-supuesto) o implementación (situación del proyecto).',
    component: DashboardPage,
    type: 'dash',
    layout: '/admin'
  },
  {
    path: '/dashboard-consolidada',
    menu: true,
    rol: [1, 2, 3, 4],
    base: 'dashboard-consolidada',
    name: 'Huella consolidada',
    nameBase: 'Dashboard',
    toltip:
      'En esta pantalla se puede consultar información relacionada a las emisiones de gases de efecto invernadero de uno o varios proyectos.',
    help: 'En esta pantalla los usuarios pueden consultar las emisiones de GEI de uno o varios proyectos, por tipo de proyecto, por año y por etapa (planeación o implementación). Los reportes generados incluyen las emisiones por Alcance I, II y III, las emisiones por las fases de construcción y operativas del proyecto, y las emisiones por etapa, ya sea de planeación (pre-supuesto) o implementación (situación del proyecto).',
    component: DashboardConsolidado,
    type: 'dash',
    layout: '/admin'
  },

  {
    path: '/organizacion',
    name: 'Gestión de la cuenta',
    menu: true,
    rol: [1],
    nameBase: 'Gestión de la cuenta',
    base: 'organizacion',
    toltip:
      'En esta pantalla el usuario administrador de seguridad debe configurar los parámetros de la cuenta.',
    help: 'En esta pantalla el usuario administrador de seguridad puede configurar los tipos de proyectos a evaluar, y los países relacionados con la medición de los gases de efecto invernadero. Esta sección del sistema debe configurarse basado en la estrategia de medición de la organización. ',
    component: Organizacion,
    layout: '/admin',
    type: 'admin'
  },
  {
    path: '/gestion-fuentes',
    name: 'Fuentes y ratios de GEI',
    menu: true,
    rol: [1],
    nameBase: 'Fuentes y ratios de GEI',
    base: 'Gestiónfuentes',
    toltip:
      'En esta pantalla el usuario administrador de seguridad debe configurar las opciones recomendadas de fuentes y ratios de GEI por tipo de proyecto.',
    help: 'En esta pantalla el usuario administrador de seguridad debe seleccionar las opciones recomendadas de fuentes y ratios de GEI para cada fase recomendada según el tipo de proyecto. Esta configuración permite agilizar el proceso de registro de información y cálculo de emisiones de GEI por tipo de proyecto.',
    component: GestiónFuentes,
    layout: '/admin',
    type: 'admin'
  },

  {
    path: '/profile',
    name: 'Perfil de Usuario',
    nameBase: 'Perfil de Usuario',
    base: 'profile',
    menu: true,
    rol: [1, 2, 3, 4],
    component: Profile,
    layout: '/admin',
    help: 'En esta pantalla cualquier usuario nombrado del sistema puede ver información de su perfil de acceso.',
    toltip:
      'Solo el usuario administrador de seguridad puede modificar el perfil de acceso.',
    type: 'user'
  },
  {
    path: '/user',
    name: 'Usuario',
    nameBase: 'Gestión de Usuario',
    base: 'userlist',
    menu: false,
    rol: [1, 2],
    component: UserForm,
    layout: '/admin',
    type: 'user',
    help: 'En esta pantalla cualquier usuario nombrado del sistema puede ver información de su perfil de acceso.',
    toltip:
      'Solo el usuario administrador de seguridad puede modificar el perfil de acceso.'
  },
  {
    path: '/userlist',
    nameBase: 'Lista de Usuarios',
    name: 'Lista de Usuarios',
    menu: true,
    rol: [1, 2],
    component: UserList,
    base: 'userlist',
    help: 'En esta pantalla el usuario administrador de seguridad puede habilitar un nuevo usuario y configurar su perfil de acceso. Adicionalmente puede Gestiónar la lista de usuarios, creando y eliminando usuario.',
    toltip:
      'En esta pantalla el usuario administrador de seguridad Gestióna el acceso a la cuenta por parte de su comunidad de usuarios.',
    layout: '/admin',
    type: 'user'
  },
  // Proyecto
  {
    path: '/registro-referencia',
    name: 'Situacion de Referencia',
    menu: true,
    rol: [1, 3],
    nameBase: 'Situacion de Referencia',
    base: 'registro-referencia',
    component: ReferenciaLista,
    layout: '/admin',
    type: 'reg',
    toltip:
      'Selecciona la fase correspondiente del proyecto para realizar o editar una medición de GEI.'
  },
  {
    path: '/lista-registro',
    name: 'Registro de Datos',
    menu: true,
    rol: [1, 3],
    nameBase: 'Registro de Datos',
    base: 'lista-registro',
    component: RegistroHuella,
    layout: '/admin',
    type: 'reg',
    help: 'En esta pantalla el usuario reponsable de gestionar registros de datos de actividad sobre los proyectos puede actualizar mediciones existentes de GEI o realizar nuevas mediciones para un periodo en evaluación.',
    toltip:
      'En esta pantalla el usuario de registro pueda actualizar mediciones de GEI o o realizar nuevas mediciones para las distintas fases de un proyecto.'
  },
  {
    path: '/lista-proyecto',
    name: 'Perfil de Proyectos',
    menu: true,
    rol: [1, 3],
    nameBase: 'Perfil de Proyectos',
    base: 'proyecto',
    component: ProyectoLista,
    layout: '/admin',
    type: 'reg',
    help: 'En esta pantalla el usuario responsable de gestionar registros puede crear nuevos proyectos o editar proyectos existentes para la actualización de datos y mediciones de GEI.',
    toltip:
      'En esta pantalla los usuarios administradores de registro pueden crear nuevos proyectos o actualizar mediciones en proceso.'
  },
  {
    path: '/form-proyecto',
    name: 'Editar proyecto',
    menu: false,
    rol: [1, 3],
    base: 'proyecto',
    nameBase: 'Perfil del Proyecto',
    component: ProyectoForm,
    layout: '/admin',
    type: 'reg',
    help: 'Completa toda la información relacionada con el proyecto sobre el cual realizarán mediciones de GEI para fases de construcción y operativas.',
    toltip:
      'En esta pantalla el usuario administrador de seguridad o de registro pueden crear un nuevo proyecto o bien editar algunos parámetros del mismo.'
  },
  {
    path: '/form-preview',
    name: 'Editar proyecto',
    menu: false,
    rol: [1, 3],
    base: 'lista-registro',
    nameBase: 'Perfil del Proyecto',
    component: PreviewForm,
    layout: '/admin',
    type: 'reg',
    help: 'Completa toda la información relacionada con el proyecto sobre el cual realizarán mediciones de GEI para fases de construcción y operativas.',
    toltip:
      'En esta pantalla el usuario administrador de seguridad o de registro pueden crear un nuevo proyecto o bien editar algunos parámetros del mismo.'
  },

  {
    path: '/referencia-item',
    name: 'Situacion de Referencia',
    menu: false,
    rol: [1, 3],
    nameBase: 'Situacion de Referencia',
    base: 'registro-referencia',
    component: ReferencaiaItem,
    layout: '/admin',
    type: 'reg',
    toltip:
      'Selecciona la fase correspondiente del proyecto para realizar o editar una medición de GEI.'
  },
  // registro

  {
    path: '/registro-item',
    name: 'Registro',
    menu: false,
    rol: [1, 3],
    nameBase: 'Medición de GEI',
    base: 'lista-registro',
    component: RegistroItem,
    layout: '/admin',
    type: 'reg',
    toltip:
      'Selecciona la fase correspondiente del proyecto para realizar o editar una medición de GEI.'
  },
  {
    path: '/registro-data',
    name: 'Registro',
    menu: false,
    rol: [1, 3],
    nameBase: 'Fase de Medición de GEI',
    base: 'lista-registro',
    component: RegistroData,
    layout: '/admin',
    type: 'reg',
    toltip:
      'En esta pantalla asegúrate de medir las emisiones de GEI para la fase correspondiente utilizando los factores o ratios pertinentes.'
  },

  {
    path: '/referencia-data',
    name: 'Registro',
    menu: false,
    rol: [1, 3],
    nameBase: 'Fase de Medición de GEI',
    base: 'registro-referencia',
    component: ReferenciaData,
    layout: '/admin',
    type: 'reg',
    toltip:
      'En esta pantalla asegúrate de medir las emisiones de GEI para la fase correspondiente utilizando los factores o ratios pertinentes.'
  },
  {
    path: '/huella-referencia',
    name: 'Huella consolidada',
    menu: false,
    rol: [1, 3, 4],
    nameBase: 'Huella consolidada',
    base: 'registro-referencia',
    component: HuellaReferencia,
    layout: '/admin',
    type: 'reg',
    toltip:
      'En esta pantalla podrás exportar el inventario de GEI de un proyecto para un año en específico de evaluación ya sea en etapa de planeación o de implementación.'
  },
  {
    path: '/huella-ficha',
    name: 'Huella consolidada',
    menu: false,
    rol: [1, 3, 4],
    nameBase: 'Huella consolidada',
    base: 'lista-registro',
    component: HuellaFicha,
    layout: '/admin',
    type: 'reg',
    toltip:
      'En esta pantalla podrás exportar el inventario de GEI de un proyecto para un año en específico de evaluación ya sea en etapa de planeación o de implementación.'
  },
  {
    path: '/registro-historico',
    name: 'Registro',
    menu: false,
    rol: [1, 3],
    nameBase: 'Fase de Medición de GEI',
    base: 'lista-registro',
    component: RegistroHistorico,
    layout: '/admin',
    type: 'reg',
    toltip:
      'En esta pantalla asegúrate de medir las emisiones de GEI para la fase correspondiente utilizando los factores o ratios pertinentes.'
  },

  // Perfil de la organizacion

  {
    path: '/factor-tree',
    name: 'Factor',
    menu: false,
    rol: [1, 2],
    component: FactorTree,
    layout: '/admin'
  },
  {
    path: '/login',
    menu: false,
    rol: [1, 2],
    component: Login,
    layout: '/login'
  },

  {
    path: '/soporte',
    menu: false,
    rol: [1, 2],
    component: LoginAdmin,
    layout: '/login'
  },
  {
    path: '/logout',
    menu: false,
    rol: [1, 2, 3, 4],
    component: Logaut,
    layout: '/login'
  },
  {
    path: '/registro',
    base: 'registro',
    menu: false,
    rol: [1, 2, 3, 4],
    component: Registro,
    layout: '/login'
  },
  {
    path: '/invitacion',
    menu: false,
    rol: [1, 2, 3, 4],
    component: Invitacion,
    layout: '/login'
  },
  {
    path: '/gracias',
    menu: false,
    rol: [1, 2, 3, 4],
    component: Gracias,
    layout: '/login'
  },
  {
    path: '/reset-passwowd',
    name: 'Recuperar Contraseña',
    menu: false,
    rol: [1, 2, 3, 4],
    component: RecuperaContrasena,
    layout: '/login'
  },
  {
    path: '/',
    rol: [1, 2, 3, 4],
    menu: false,
    component: Login,
    layout: '/login'
  }
]

export default dashboardRoutes