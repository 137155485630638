import React, { useEffect, useState } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import PerfectScrollbar from 'perfect-scrollbar'
import 'perfect-scrollbar/css/perfect-scrollbar.css'
import { makeStyles } from '@material-ui/core/styles'
import Navbar from 'components/Header'
import Footer from 'components/Footer'
import Sidebar from 'components/Menu'
import { useLocation } from 'react-router-dom'
import routes from 'routes.js'
import styles from './style.js'
import { Help } from '@material-ui/icons/'

import logo from 'assets/img/logo-bcie.png'
import { IconButton, Tooltip } from '@material-ui/core'

let ps
const switchRoutes = (
  <Switch>
    {routes.map((prop, key) => {
      return <Route path={prop.path} component={prop.component} key={key} />
    })}
    <Redirect from='/' to='/' />
  </Switch>
)

const useStyles = makeStyles(styles)

const Admin = ({ ...rest }) => {
  const location = useLocation()
  const classes = useStyles()
  const mainPanel = React.createRef()
  const [mobileOpen, setMobileOpen] = useState(false)
  const [currentMenu, setCurrentMenu] = useState(false)
  const [menu, setMenu] = useState([])

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false)
    }
  }
  useEffect(() => {
    const menu = routes.find(item => item.path === location.pathname)
    setMenu(menu)
  })
  useEffect(() => {
    const men = routes.find(p => {
      return p.path === location.pathname
    })
    if (men) {
      setCurrentMenu(men.layout === '/admin')
    }
    if (navigator.platform.indexOf('Win') > -1) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false
      })
      document.body.style.overflow = 'hidden'
    }
    window.addEventListener('resize', resizeFunction)
    return function cleanup () {
      if (navigator.platform.indexOf('Win') > -1) {
        ps.destroy()
      }
      window.removeEventListener('resize', resizeFunction)
    }
  }, [mainPanel])

  return (
    <div className={classes.wrapper}>
      {currentMenu && (
        <Sidebar
          history={history}
          routes={routes}
          logo={logo}
          handleDrawerToggle={handleDrawerToggle}
          open={mobileOpen}
          color='blue'
          {...rest}
        />
      )}
      <div
        className={currentMenu ? classes.mainPanel : classes.mainPanelFull}
        ref={mainPanel}
      >
        {currentMenu && (
          <Navbar
            routes={routes}
            handleDrawerToggle={handleDrawerToggle}
            {...rest}
          />
        )}

        <div className={classes.content}>
          {menu && menu.nameBase && (
            <>
              <div className={classes.title}>
                {menu.nameBase}
                <Tooltip title={menu.toltip} placement='top-start'>
                  <IconButton aria-label='delete' color='success'>
                    <Help style={{ cursor: 'help' }} />
                  </IconButton>
                </Tooltip>
              </div>
              <div className={classes.subtitle}>{menu.help}</div>
            </>
          )}
          <div className={classes.container}>{switchRoutes}</div>
        </div>

        {currentMenu && <Footer />}
      </div>
    </div>
  )
}

export default Admin
