import API from './../Api'

class FactorService {
  static getAll() {
    return API({
      method: 'GET',
      url: '/api/v1/factor/factor_tree/'
    })
  }
  static factoresFamilia(id, token) {
    return API({
      method: 'GET',
      url: `api/v1/factor/factor/?familia=${id}`,
      headers: {
        Authorization: 'Bearer ' + token,
        'Strict-Transport-Security':
          'max-age=31536000; includeSubDomains; preload',
        'Content-Security-Policy':
          "default-src https: data: blob: 'unsafe-inline' 'unsafe-eval'",
        'X-Content-Type-Options': 'nosniff',
        'X-Frame-Options': 'DENY',
        'X-XSS-Protection': '1; mode=block',
        'X-Content-Type-Options': 'nosniff',
        'Referrer-Policy': 'no-referrer-when-downgrade',
        'Permissions-Policy': 'camera=(), microphone=(), geolocation=()'
      }
    })
  }
  static factoresOrigenEmision(id, token) {
    return API({
      method: 'GET',
      url: `api/v1/factor/factor/?familia=${id}`,
      headers: {
        Authorization: 'Bearer ' + token,
        'Strict-Transport-Security':
          'max-age=31536000; includeSubDomains; preload',
        'Content-Security-Policy':
          "default-src https: data: blob: 'unsafe-inline' 'unsafe-eval'",
        'X-Content-Type-Options': 'nosniff',
        'X-Frame-Options': 'DENY',
        'X-XSS-Protection': '1; mode=block',
        'X-Content-Type-Options': 'nosniff',
        'Referrer-Policy': 'no-referrer-when-downgrade',
        'Permissions-Policy': 'camera=(), microphone=(), geolocation=()'
      }
    })
  }
  static ratiosFamilia(id, token) {
    return API({
      method: 'GET',
      url: `/api/v1/factor/ratio/?familia=${id}`,
      headers: {
        Authorization: 'Bearer ' + token,
        'Strict-Transport-Security':
          'max-age=31536000; includeSubDomains; preload',
        'Content-Security-Policy':
          "default-src https: data: blob: 'unsafe-inline' 'unsafe-eval'",
        'X-Content-Type-Options': 'nosniff',
        'X-Frame-Options': 'DENY',
        'X-XSS-Protection': '1; mode=block',
        'X-Content-Type-Options': 'nosniff',
        'Referrer-Policy': 'no-referrer-when-downgrade',
        'Permissions-Policy': 'camera=(), microphone=(), geolocation=()'
      }
    })
  }

  static getFactores(token) {
    return API({
      method: 'GET',
      url: '/api/v1/factor/factor/',
      headers: {
        Authorization: `Token ${token}`,
        'Strict-Transport-Security':
          'max-age=31536000; includeSubDomains; preload',
        'Content-Security-Policy':
          "default-src https: data: blob: 'unsafe-inline' 'unsafe-eval'",
        'X-Content-Type-Options': 'nosniff',
        'X-Frame-Options': 'DENY',
        'X-XSS-Protection': '1; mode=block',
        'X-Content-Type-Options': 'nosniff',
        'Referrer-Policy': 'no-referrer-when-downgrade',
        'Permissions-Policy': 'camera=(), microphone=(), geolocation=()'
      }
    })
  }
  static getRatios(token) {
    return API({
      method: 'GET',
      url: '/api/v1/factor/ratio/',
      headers: {
        Authorization: `Token ${token}`,
        'Strict-Transport-Security':
          'max-age=31536000; includeSubDomains; preload',
        'Content-Security-Policy':
          "default-src https: data: blob: 'unsafe-inline' 'unsafe-eval'",
        'X-Content-Type-Options': 'nosniff',
        'X-Frame-Options': 'DENY',
        'X-XSS-Protection': '1; mode=block',
        'X-Content-Type-Options': 'nosniff',
        'Referrer-Policy': 'no-referrer-when-downgrade',
        'Permissions-Policy': 'camera=(), microphone=(), geolocation=()'
      }
    })
  }

  static getGestionFuente(
    token,
    tipo_proyecto,
    categoria,
    familia,
    tipo,
    search
  ) {
    return API({
      method: 'GET',
      url: `/api/v1/proyecto/gestion_fuente/?tipo_proyecto=${tipo_proyecto}&categoria=${categoria}&familia=${familia}&tipo=${tipo}&buscar=${search} `,
      headers: {
        Authorization: `Token ${token}`,
        'Strict-Transport-Security':
          'max-age=31536000; includeSubDomains; preload',
        'Content-Security-Policy':
          "default-src https: data: blob: 'unsafe-inline' 'unsafe-eval'",
        'X-Content-Type-Options': 'nosniff',
        'X-Frame-Options': 'DENY',
        'X-XSS-Protection': '1; mode=block',
        'X-Content-Type-Options': 'nosniff',
        'Referrer-Policy': 'no-referrer-when-downgrade',
        'Permissions-Policy': 'camera=(), microphone=(), geolocation=()'
      }
    })
  }
  static saveGestionFuente(token, data) {
    return API({
      method: 'POST',
      url: `/api/v1/proyecto/gestion_fuente/`,
      data,
      headers: {
        Authorization: `Token ${token}`,
        'Strict-Transport-Security':
          'max-age=31536000; includeSubDomains; preload',
        'Content-Security-Policy':
          "default-src https: data: blob: 'unsafe-inline' 'unsafe-eval'",
        'X-Content-Type-Options': 'nosniff',
        'X-Frame-Options': 'DENY',
        'X-XSS-Protection': '1; mode=block',
        'X-Content-Type-Options': 'nosniff',
        'Referrer-Policy': 'no-referrer-when-downgrade',
        'Permissions-Policy': 'camera=(), microphone=(), geolocation=()'
      }
    })
  }
  static deleteFactorRatio(token, id, data) {
    return API({
      method: 'PUT',
      url: `/api/v1/proyecto/gestion_fuente/${id}/`,
      data,
      headers: {
        Authorization: `Token ${token}`,
        'Strict-Transport-Security':
          'max-age=31536000; includeSubDomains; preload',
        'Content-Security-Policy':
          "default-src https: data: blob: 'unsafe-inline' 'unsafe-eval'",
        'X-Content-Type-Options': 'nosniff',
        'X-Frame-Options': 'DENY',
        'X-XSS-Protection': '1; mode=block',
        'X-Content-Type-Options': 'nosniff',
        'Referrer-Policy': 'no-referrer-when-downgrade',
        'Permissions-Policy': 'camera=(), microphone=(), geolocation=()'
      }
    })
  }
  static buscarFactores(search, proyecto, token) {
    return API({
      method: 'GET',
      url: `/api/v1/factor/factor/?buscar=${search}&proyecto=${proyecto}`,
      headers: {
        Authorization: `Token ${token}`,
        'Strict-Transport-Security':
          'max-age=31536000; includeSubDomains; preload',
        'Content-Security-Policy':
          "default-src https: data: blob: 'unsafe-inline' 'unsafe-eval'",
        'X-Content-Type-Options': 'nosniff',
        'X-Frame-Options': 'DENY',
        'X-XSS-Protection': '1; mode=block',
        'X-Content-Type-Options': 'nosniff',
        'Referrer-Policy': 'no-referrer-when-downgrade',
        'Permissions-Policy': 'camera=(), microphone=(), geolocation=()'
      }
    })
  }
  static buscarRatios(search, proyecto, token) {
    return API({
      method: 'GET',
      url: `/api/v1/factor/ratio/?buscar=${search}&proyecto=${proyecto}`,
      headers: {
        Authorization: `Token ${token}`,
        'Strict-Transport-Security':
          'max-age=31536000; includeSubDomains; preload',
        'Content-Security-Policy':
          "default-src https: data: blob: 'unsafe-inline' 'unsafe-eval'",
        'X-Content-Type-Options': 'nosniff',
        'X-Frame-Options': 'DENY',
        'X-XSS-Protection': '1; mode=block',
        'X-Content-Type-Options': 'nosniff',
        'Referrer-Policy': 'no-referrer-when-downgrade',
        'Permissions-Policy': 'camera=(), microphone=(), geolocation=()'
      }
    })
  }
}

export default FactorService
