import React, { useState, useEffect } from 'react'
import {
  Modal,
  makeStyles,
  Fade,
  TableContainer,
  TableCell,
  TableBody,
  TableHead,
  TableRow,
  Table,
  FormControl,
  Paper,
  Backdrop,
  IconButton
} from '@material-ui/core/index'
import GridItem from 'components/Grid/GridItem'
import NumberInput from 'components/NumberInput/index'
import CardFooter from 'components/Card/Footer/index'
import Button from 'components/CustomButtons'

import ProyectoService from '../../../services/proyecto'
import { useToasts } from 'react-toast-notifications'
import { Backup, Edit } from '@material-ui/icons/index'

const useStyles = makeStyles((theme) => ({
  root: {
    height: 240,
    flexGrow: 1,
    maxWidth: 400
  },
  base: {
    width: '139px',
    padding: '5px',
    background: '#FFFFFF',
    borderBottom: '1px solid #D4D6D6',
    boxSizing: 'border-box',
    borderRadius: '5px',
    textAlign: 'center'
  },
  editIcon: {
    position: 'absolute',
    marginLeft: '110px',
    marginTop: '-22px',
    cursor: 'pointer'
  },
  label: {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '12px',
    color: '#999999'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    paddingTop: '20px',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 6, 3)
  }
}))

const Registro = ({
  valor,
  valores,
  loadRegistros,
  id,
  fuente,
  handleLoading,
  alcance,
  token
}) => {
  const { addToast } = useToasts()
  const classes = useStyles()
  const [parametros, setParametros] = useState([])
  const [open, setOpen] = useState(false)
  const handleParametro = (id, val) => {
    try {
      const par = parametros.map((p) => {
        if (p.id === id) {
          p.valor = val
        }
        return p
      })
      setParametros(par)
    } catch (e) {
      console.log(e)
    }
  }
  const { unidad_medida } = fuente
  useEffect(() => {
    try {
      if (valores !== null) {
        setParametros(valores)
      } else {
        const { unidad_medida } = fuente
        setParametros(unidad_medida.parametros)
      }
    } catch (e) {
      console.log(e)
    }
  }, [valores])

  const handleClose = () => {
    setOpen(false)
  }

  const handleSubmit = async () => {
    try {
      const data = {
        valores: parametros,
        alcance: alcance
      }
      handleLoading(true)
      const resp = await ProyectoService.updateRegistro(id, data, token)
      if (resp.status === 200) {
        handleClose()
        addToast('Registro guardado', { appearance: 'success' })
        loadRegistros()
      }
    } catch (e) {
      addToast('Error en el proceso', { appearance: 'error' })
      handleClose()
    }
  }

  const onChange = async (e) => {
    const file = e.target.files[0]
    const formData = new FormData()
    formData.append('image', file)
    formData.append('id', id)
    const result = await ProyectoService.upload(formData, token)
    if (result.status === 200) {
      addToast('Archivo adjuntado correctamente', {
        appearance: 'success',
        autoDismiss: true
      })
      loadRegistros()
    }
  }

  return (
    <div>
      <div className={classes.base} onClick={() => setOpen(true)}>
        <div>{parseFloat(valor.toFixed(2)).toLocaleString('en')} </div>
        <div className={classes.editIcon}>
          <Edit style={{ fill: '#15bd85' }} />
        </div>
      </div>
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <GridItem xs={12} sm={12} md={12} style={{ marginTop: '40px' }}>
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell component="th">
                        <div>Fuente de emisión de GEI</div>
                      </TableCell>
                      {parametros &&
                        parametros.map((p, i) => (
                          <TableCell component="th">
                            Dato de actividad {i + 1}
                          </TableCell>
                        ))}
                      <TableCell component="th">Unidad de medida</TableCell>
                      <TableCell component="th">Verificable</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell component="td" scope="row">
                        <div className={classes.label}>{fuente.nombre}</div>
                      </TableCell>

                      {parametros &&
                        parametros.map((p, i) => (
                          <>
                            <TableCell component="td" scope="row">
                              <FormControl fullWidth>
                                <NumberInput
                                  defaultValue={p.valor}
                                  value={p.valor}
                                  required
                                  labelText={p.nombre}
                                  onChange={(e) =>
                                    handleParametro(p.id, e.target.value)
                                  }
                                  formControlProps={{
                                    fullWidth: true
                                  }}
                                />
                              </FormControl>
                            </TableCell>
                            {unidad_medida && i === 0 && (
                              <TableCell component="td" scope="row">
                                {unidad_medida.codigo}
                              </TableCell>
                            )}
                          </>
                        ))}

                      <input
                        style={{ display: 'none' }}
                        accept="image/*,application/pdf"
                        onChange={(e) => onChange(e)}
                        className={classes.input}
                        id="icon-button-file"
                        type="file"
                      />
                      <TableCell component="td" scope="row">
                        <label htmlFor="icon-button-file">
                          <IconButton
                            color="primary"
                            aria-label="upload picture"
                            component="span"
                          >
                            <Backup color="#454545" />
                          </IconButton>
                        </label>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <CardFooter>
                <Button
                  type="button"
                  onClick={() => handleSubmit()}
                  color="success"
                >
                  Guardar
                </Button>
              </CardFooter>
            </GridItem>
          </div>
        </Fade>
      </Modal>
    </div>
  )
}

export default Registro
