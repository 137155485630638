import React, { useEffect, useState } from 'react'

import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

require('highcharts/modules/exporting')(Highcharts)
require('highcharts/modules/export-data')(Highcharts)
import { IconButton, Tooltip } from '@material-ui/core/index'
import { Help } from '@material-ui/icons/index'

import Card from 'components/Card/Card'
import CardBody from 'components/Card/Body/index.js'
import CardHeader from 'components/Card/Header/index'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'

Highcharts.setOptions({
  chart: {
    height: 500,
    borderWidth: 0,
    plotBackgroundColor: 'rgba(255, 255, 255, .9)',
    plotShadow: true,
    plotBorderWidth: 1
  },
  tooltip: {
    valueDecimals: 2
  },
  loading: {
    hideDuration: 50,
    showDuration: 70
  },
  exporting: {
    filename: 'Grafica Neutralbank',
    scale: 4,
    sourceWidth: 1000
  },
  lang: {
    decimalPoint: '.',
    printChart: 'Imprimir',
    downloadJPEG: 'Descargar JPEG image',
    downloadPDF: 'Descargar PDF',
    downloadPNG: 'Descargar PNG',
    downloadSVG: 'Descargar SVG',
    downloadCSV: 'Descargar CSV',
    downloadXLS: 'Descargar XLS'
  }
})

const ConsolidadaAlcance = ({ data, categories, anio }) => {
  const [empty, setEmpty] = useState(true)
  const [implementacionOptions, setImplementacionOptions] = useState({})
  useEffect(() => {
    try {
      let subtitle = categories === 0 ? ': Año base' : `: ${anio ? anio : ''}`
      let h2 = [0, 0, 0]
      let title = ''
      if (categories === 0) {
        title = 'Situación de Referencia'
        h2 = [
          parseFloat(data.referencia.ambito1.toFixed(2)),
          parseFloat(data.referencia.ambito2.toFixed(2)),
          parseFloat(data.referencia.ambito3.toFixed(2))
        ]
      } else if (categories === 1) {
        title = 'Planeación'
        h2 = [
          parseFloat(data.construccion.ambito1.toFixed(2)),
          parseFloat(data.construccion.ambito2.toFixed(2)),
          parseFloat(data.construccion.ambito3.toFixed(2))
        ]
      } else if (categories === 2) {
        title = 'Implementación'
        h2 = [
          parseFloat(data.operativa.ambito1.toFixed(2)),
          parseFloat(data.operativa.ambito2.toFixed(2)),
          parseFloat(data.operativa.ambito3.toFixed(2))
        ]
      }

      const implementacionOptions = {
        chart: {
          type: 'bar'
        },
        title: {
          text: 'Huella por alcance'
        },
        subtitle: {
          text: `tCO2e${subtitle}`
        },

        credits: {
          href: 'https://www.neutralbank.app',
          text: 'www.neutralbank.app'
        },

        yAxis: {
          title: {
            text: `${title} tCO2e`,
            align: 'high'
          },
          labels: {
            overflow: 'justify'
          }
        },
        plotOptions: {
          bar: {
            dataLabels: {
              enabled: true
            }
          }
        },
        tooltip: {
          valueSuffix: ' tCO2e'
        },
        xAxis: {
          categories: ['Alcance 1', 'Alcance 2', 'Alcance 3'],
          crosshair: true
        },
        series: [
          {
            name: 'tCO2e',
            color: '#A3A3A3',
            data: h2
          }
        ]
      }
      setImplementacionOptions(implementacionOptions)
    } catch (e) {
      console.log(e)
    }
  }, [data, categories, anio])

  return (
    <CardBody>
      <GridItem xs={12} sm={12} md={12}>
        <Card chart>
          <CardHeader plain color="primary">
            <GridContainer>
              <GridItem xs={12} sm={12} md={12} lg={11}>
                <h4>
                  E) Emisiones consolidadas por alcance de proyecto (GHG
                  Protocol) - tCO2e
                </h4>
              </GridItem>
              <GridItem lg={1} style={{ float: 'right', textAlign: 'right' }}>
                <Tooltip
                  title="En esta gráfica se muestran las emisiones de GEI por alcance I, II y III, de uno o varios proyectos de manera consolidada, y en función de la etapa que se está evaluando durante el periodo de consulta."
                  placement="top-start"
                >
                  <IconButton
                    aria-label="delete"
                    color="success"
                    style={{ marginTop: '27px' }}
                  >
                    <Help style={{ cursor: 'pointer' }} />
                  </IconButton>
                </Tooltip>
              </GridItem>
            </GridContainer>
          </CardHeader>

          <CardBody>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12} lg={12}>
                <HighchartsReact
                  highcharts={Highcharts}
                  constructorType={'chart'}
                  options={implementacionOptions}
                />
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </GridItem>
    </CardBody>
  )
}

export default ConsolidadaAlcance
