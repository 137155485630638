import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import GridItem from 'components/Grid/GridItem.js'
import GridContainer from 'components/Grid/GridContainer.js'
import CustomInput from 'components/CustomInput'
import Error from 'components/Error'
import Button from 'components/CustomButtons'
import Card from 'components/Card/Card'
import CardHeader from 'components/Card/Header/index.js'
import CardBody from 'components/Card/Body/index.js'
import CardFooter from 'components/Card/Footer'
import { Style } from '../LoginAdmin/style'
import { useToasts } from 'react-toast-notifications'
import AuthService from 'services/auth/index'

const useStyles = makeStyles(Style)

const RecuperaContrasena = ({ history }) => {
  const classes = useStyles()
  const { addToast } = useToasts()
  const [email, setEmail] = useState('')

  const onSubmit = async data => {
    if (!email) {
      addToast('Ingrese su correo', { appearance: 'error', autoDismiss: true })
      return
    }
    try {
      const user = await AuthService.resetPasswor(email)
      if (user.status === 201) {
        localStorage.setItem('user', JSON.stringify(user.data))
        window.location.reload()
      }
      addToast(
        'Estimado cliente se le envio un correo con la nueva contraseña',
        { appearance: 'success' }
      )
      history.push('/login')
    } catch (error) {
      addToast('Error en el proceso', { appearance: 'error' })
    }
  }

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={6} lg={4} style={{ margin: 'auto' }}>
          <Card className={classes.login}>
            <CardHeader color='primary'>
              <h4>Recuperar Contraseña</h4>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <CustomInput
                    labelText='Email de usuario'
                    type='email'
                    value={email}
                    onchange={e => setEmail(e.target.value)}
                    formControlProps={{
                      fullWidth: true
                    }}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
            <CardFooter>
              <Button type='button' color='success' onClick={onSubmit}>
                Recuperar
              </Button>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  )
}

export default RecuperaContrasena
