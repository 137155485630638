import API from './../Api'

class CompanyService {
  static getAll(token) {
    return API({
      method: 'GET',
      url: '/api/v1/company/',
      headers: {
        Authorization: 'Bearer ' + token,
        'Strict-Transport-Security':
          'max-age=31536000; includeSubDomains; preload',
        'Content-Security-Policy':
          "default-src https: data: blob: 'unsafe-inline' 'unsafe-eval'",
        'X-Content-Type-Options': 'nosniff',
        'X-Frame-Options': 'DENY',
        'X-XSS-Protection': '1; mode=block',
        'X-Content-Type-Options': 'nosniff',
        'Referrer-Policy': 'no-referrer-when-downgrade',
        'Permissions-Policy': 'camera=(), microphone=(), geolocation=()'
      }
    })
  }
  static getById(id, token) {
    return API({
      method: 'GET',
      url: `/api/v1/company/${id}/`,
      headers: {
        Authorization: 'Bearer ' + token,
        'Strict-Transport-Security':
          'max-age=31536000; includeSubDomains; preload',
        'Content-Security-Policy':
          "default-src https: data: blob: 'unsafe-inline' 'unsafe-eval'",
        'X-Content-Type-Options': 'nosniff',
        'X-Frame-Options': 'DENY',
        'X-XSS-Protection': '1; mode=block',
        'X-Content-Type-Options': 'nosniff',
        'Referrer-Policy': 'no-referrer-when-downgrade',
        'Permissions-Policy': 'camera=(), microphone=(), geolocation=()'
      }
    })
  }
  static save(data, token) {
    return API({
      method: 'POST',
      url: '/api/v1/company/',
      data,
      headers: {
        Authorization: 'Bearer ' + token,
        'Strict-Transport-Security':
          'max-age=31536000; includeSubDomains; preload',
        'Content-Security-Policy':
          "default-src https: data: blob: 'unsafe-inline' 'unsafe-eval'",
        'X-Content-Type-Options': 'nosniff',
        'X-Frame-Options': 'DENY',
        'X-XSS-Protection': '1; mode=block',
        'X-Content-Type-Options': 'nosniff',
        'Referrer-Policy': 'no-referrer-when-downgrade',
        'Permissions-Policy': 'camera=(), microphone=(), geolocation=()'
      }
    })
  }
  static update(id, data, token) {
    return API({
      method: 'PUT',
      url: `/api/v1/company/${id}/`,
      data,
      headers: {
        Authorization: 'Bearer ' + token,
        'Strict-Transport-Security':
          'max-age=31536000; includeSubDomains; preload',
        'Content-Security-Policy':
          "default-src https: data: blob: 'unsafe-inline' 'unsafe-eval'",
        'X-Content-Type-Options': 'nosniff',
        'X-Frame-Options': 'DENY',
        'X-XSS-Protection': '1; mode=block',
        'X-Content-Type-Options': 'nosniff',
        'Referrer-Policy': 'no-referrer-when-downgrade',
        'Permissions-Policy': 'camera=(), microphone=(), geolocation=()'
      }
    })
  }
}

export default CompanyService
