import API from './../Api'

class HuellaService {
  static getHuella(id, anio, token) {
    return API({
      method: 'GET',
      url: `/api/v1/huella/huella/?proyecto=${id}&anio=${anio}&token=${token}`,
      headers: {
        Authorization: 'Bearer ' + token,
        'Strict-Transport-Security':
          'max-age=31536000; includeSubDomains; preload',
        'Content-Security-Policy':
          "default-src https: data: blob: 'unsafe-inline' 'unsafe-eval'",
        'X-Content-Type-Options': 'nosniff',
        'X-Frame-Options': 'DENY',
        'X-XSS-Protection': '1; mode=block',
        'X-Content-Type-Options': 'nosniff',
        'Referrer-Policy': 'no-referrer-when-downgrade',
        'Permissions-Policy': 'camera=(), microphone=(), geolocation=()'
      }
    })
  }

  static getHuellaReferencia(id, token) {
    return API({
      method: 'GET',
      url: `/api/v1/huella/huellareferencia/?proyecto=${id}&token=${token}`,
      headers: {
        Authorization: 'Bearer ' + token,
        'Strict-Transport-Security':
          'max-age=31536000; includeSubDomains; preload',
        'Content-Security-Policy':
          "default-src https: data: blob: 'unsafe-inline' 'unsafe-eval'",
        'X-Content-Type-Options': 'nosniff',
        'X-Frame-Options': 'DENY',
        'X-XSS-Protection': '1; mode=block',
        'X-Content-Type-Options': 'nosniff',
        'Referrer-Policy': 'no-referrer-when-downgrade',
        'Permissions-Policy': 'camera=(), microphone=(), geolocation=()'
      }
    })
  }

  static getReferencia(id, anio, token) {
    return API({
      method: 'GET',
      url: `/api/v1/huella/referencia/?proyecto=${id}&anio=${anio}`,
      headers: {
        Authorization: 'Bearer ' + token,
        'Strict-Transport-Security':
          'max-age=31536000; includeSubDomains; preload',
        'Content-Security-Policy':
          "default-src https: data: blob: 'unsafe-inline' 'unsafe-eval'",
        'X-Content-Type-Options': 'nosniff',
        'X-Frame-Options': 'DENY',
        'X-XSS-Protection': '1; mode=block',
        'X-Content-Type-Options': 'nosniff',
        'Referrer-Policy': 'no-referrer-when-downgrade',
        'Permissions-Policy': 'camera=(), microphone=(), geolocation=()'
      }
    })
  }

  static getAnios(id, token) {
    return API({
      method: 'GET',
      url: `/api/v1/huella/anios/?proyecto=${id}`,
      headers: {
        Authorization: 'Bearer ' + token,
        'Strict-Transport-Security':
          'max-age=31536000; includeSubDomains; preload',
        'Content-Security-Policy':
          "default-src https: data: blob: 'unsafe-inline' 'unsafe-eval'",
        'X-Content-Type-Options': 'nosniff',
        'X-Frame-Options': 'DENY',
        'X-XSS-Protection': '1; mode=block',
        'X-Content-Type-Options': 'nosniff',
        'Referrer-Policy': 'no-referrer-when-downgrade',
        'Permissions-Policy': 'camera=(), microphone=(), geolocation=()'
      }
    })
  }
}

export default HuellaService
