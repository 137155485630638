import React, { useEffect, useState } from 'react'

import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

require('highcharts/modules/exporting')(Highcharts)
require('highcharts/modules/export-data')(Highcharts)

import Card from 'components/Card/Card'
import CardBody from 'components/Card/Body/index.js'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import { IconButton, Tooltip } from '@material-ui/core/index'
import { Help } from '@material-ui/icons/index'
import CardHeader from 'components/Card/Header/index'

Highcharts.setOptions({
  chart: {
    backgroundColor: {
      stops: [
        [0, 'rgb(255, 255, 255)'],
        [1, 'rgb(255, 255, 255)']
      ]
    },
    height: 500,
    borderWidth: 0,
    plotBackgroundColor: 'rgba(255, 255, 255, .9)',
    plotShadow: true,
    plotBorderWidth: 1
  },
  tooltip: {
    valueDecimals: 2
  },
  loading: {
    hideDuration: 50,
    showDuration: 70
  },
  exporting: {
    filename: 'Grafica Neutralbank',
    scale: 4,
    showTable: false,
    sourceWidth: 1000
  },
  lang: {
    decimalPoint: '.',
    printChart: 'Imprimir',
    downloadJPEG: 'Descargar JPEG image',
    downloadPDF: 'Descargar PDF',
    downloadPNG: 'Descargar PNG',
    downloadSVG: 'Descargar SVG'
  }
})

const Huella = ({ data, anio, categories }) => {
  const [planeacionOptions, setPlaneacionOptions] = useState({})
  const [empty, setEmpty] = useState(true)
  useEffect(() => {
    const cat = data.map((item) => {
      return item.nombre
    })
    let subtitle =
      categories === 0 ? ': Año base' : `${anio ? ': ' + anio : ''}`
    let alcance1 = 0
    let alcance2 = 0
    let alcance3 = 0
    if (categories === 0) {
      alcance3 = data.map((item) => {
        return parseFloat(item.referencia.ambito3.toFixed(2))
      })
      alcance2 = data.map((item) => {
        return parseFloat(item.referencia.ambito2.toFixed(2))
      })
      alcance1 = data.map((item) => {
        return parseFloat(item.referencia.ambito1.toFixed(2))
      })
    } else if (categories === 1) {
      alcance3 = data.map((item) => {
        return parseFloat(item.construccion.ambito3.toFixed(2))
      })
      alcance2 = data.map((item) => {
        return parseFloat(item.construccion.ambito2.toFixed(2))
      })
      alcance1 = data.map((item) => {
        return parseFloat(item.construccion.ambito1.toFixed(2))
      })
    } else if (categories === 2) {
      alcance3 = data.map((item) => {
        return parseFloat(item.operativa.ambito3.toFixed(2))
      })
      alcance2 = data.map((item) => {
        return parseFloat(item.operativa.ambito2.toFixed(2))
      })
      alcance1 = data.map((item) => {
        return parseFloat(item.operativa.ambito1.toFixed(2))
      })
    }
    if (alcance1 === 0 && alcance2 === 0 && alcance3 === 0) {
      setEmpty(true)
    } else {
      setEmpty(false)
    }
    const planeacion = {
      chart: {
        type: 'bar'
      },
      title: {
        text: 'Proyectos'
      },
      subtitle: {
        text: `tCO2e${subtitle}`
      },
      xAxis: {
        categories: cat
      },
      credits: {
        href: 'https://www.neutralbank.app',
        text: 'www.neutralbank.app'
      },
      yAxis: {
        title: {
          text: 'tCO2e'
        }
      },
      legend: {
        reversed: true
      },
      plotOptions: {
        bar: {
          dataLabels: {
            enabled: true
          }
        }
      },
      series: [
        {
          name: 'Alcance 3',
          color: '#A3A3A3',
          data: alcance3
        },
        {
          name: 'Alcance 2',
          color: '#26A2BD',
          data: alcance2
        },
        {
          name: 'Alcance 1',
          color: '#15BD85',
          data: alcance1
        }
      ]
    }
    setPlaneacionOptions(planeacion)
  }, [data, categories, anio])

  return (
    <CardBody>
      <GridItem xs={12} sm={12} md={12}>
        <Card chart>
          <CardHeader plain color="primary">
            <GridContainer>
              <GridItem xs={12} sm={12} md={12} lg={11}>
                <h4>
                  A) Emisiones consolidadas de Proyectos (GHG Protocol) - tCO<small>2e</small>
                </h4>
              </GridItem>
              <GridItem lg={1} style={{ float: 'right', textAlign: 'right' }}>
                <Tooltip
                  title="En esta gráfica se muestran las emisiones consolidadas de GEI por alcance I, II y III, de uno o varios proyectos, y en función de la etapa que se está evaluando durante el periodo de consulta."
                  placement="top-start"
                >
                  <IconButton
                    aria-label="delete"
                    color="success"
                    style={{ marginTop: '27px' }}
                  >
                    <Help style={{ cursor: 'pointer' }} />
                  </IconButton>
                </Tooltip>
              </GridItem>
            </GridContainer>
          </CardHeader>
          <CardBody>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12} lg={12}>
                <div style={{ minHeight: '500px' }}>
                  {empty ? (
                    <div style={{ textAlign: 'center' }}>
                      <h4>
                        De los proyectos seleccionados no tenemos datos para
                        graficar
                      </h4>
                    </div>
                  ) : (
                    <HighchartsReact
                      highcharts={Highcharts}
                      constructorType={'chart'}
                      options={planeacionOptions}
                    />
                  )}
                </div>
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </GridItem>
    </CardBody>
  )
}

export default Huella
